@font-face {
    font-family: montserrat;
    font-weight: 300;
    font-style: normal;
    src: local('Montserrat Light'),local('Montserrat-Light'),url(../fonts/montserrat-light.woff2) format('woff2');
    font-display: swap
}

@font-face {
    font-family: montserrat;
  font-weight: 400;
  font-style: normal;
  src: local('Montserrat Regular'),local('Montserrat-Regular'),url(../fonts/montserrat-regular.woff2) format('woff2');
  font-display: swap
}

@font-face {
    font-family: montserrat;
  font-weight: 700;
  font-style: normal;
  src: local('Montserrat Bold'),local('Montserrat-Bold'),url(../fonts/montserrat-bold.woff2) format('woff2');
  font-display: swap
}

@font-face {
    font-family: nowayregular;
  font-weight: 400;
  font-style: normal;
  src: url(../fonts/noway-regular.woff2) format('woff2');
  font-display: swap
}

@font-face {
    font-family: asap;
  font-weight: 700;
  font-style: normal;
  src: local('Asap SemiBold'),local('Asap-SemiBold'),url(../fonts/asap-bold.woff2) format('woff2');
  font-display: swap
}

@font-face {
    font-family: asap;
  font-weight: 600;
  font-style: normal;
  src: local('Asap Bold'),local('Asap-Bold'),url(../fonts/asap-semibold.woff2) format('woff2');
  font-display: swap
}

html {
    font-size: 100%;
    overflow-x: hidden
}

body {
    font-family: Open-sans,sans-serif;
    font-size: 13px;
    color: #000
}

hr {
    color: #d4d7d9;
    border: 1px inset#d4d7d9
}

.fixed {
    position: fixed
}

.relative {
    position: relative
}

.absolute {
    position: absolute
}

.static {
    position: static;
    overflow: hidden
}

.hyphenate {
    text-align: left;
    -webkit-hyphens: auto;
    hyphens: auto;
    -ms-hyphens: auto
}

.inline {
    display: inline
}

.inlineblock {
    display: inline-block
}

.block {
    display: block
}

.floatleft {
    float: left
}

.floatright {
    float: right
}

.floatnone {
    float: none
}

.textleft {
    text-align: left
}

.textright {
    text-align: right
}

.textcenter {
    text-align: center
}

.textjustify {
    text-align: justify
}

.width80 {
    width: 80%
}

.width60 {
    width: 60%
}

.width50 {
    width: 50%
}

.width47 {
    width: 47%
}

.width45 {
    width: 45%
}

.width35 {
    width: 35%
}

.width33 {
    width: 33.33333333%
}

.width25 {
    width: 25%
}

.bold {
    font-weight: 700
}

.normal {
    font-weight: 400
}

.margin0 {
    margin: 0
}

.marginauto {
    margin: auto
}

.lineh15 {
    line-height: 1.5
}

.lineh25 {
    line-height: 2.5
}

.height465 {
    height: 465px
}

.marginright {
    margin-right: 1em
}

.marginleft {
    margin-left: 1.5em
}

.marginbottom {
    margin-bottom: 2em!important
}

.marginbottomlight {
    margin-bottom: 1em
}

.margintop {
    margin-top: 2em
}

.margintoplight {
    margin-top: 2em
}

.margintopultralight {
    margin-top: .5em
}

.margintop-2 {
    margin-top: -2em
}

.container900 {
    max-width: 900px;
    margin: 0 auto
}

.padding10 {
    padding: 10px
}

.paddingtopbot20 {
    padding: 20px 0
}

.padding1020 {
    padding: 10px 20px
}

.padding25 {
    padding: 25px
}

.padding30 {
    padding: 30px
}

.padding40 {
    padding: 40px
}

.padding60 {
    padding: 60px
}

.padding6040 {
    padding: 60px 40px
}

.padding7040 {
    padding: 70px 40px
}

.padding4070 {
    padding: 40px 3%
}

.padding4015 {
    padding: 40px 15px
}

.vmiddle {
    vertical-align: middle
}

.vtop {
    vertical-align: top
}

.vbottom {
    vertical-align: bottom
}

.fontsize80 {
    font-size: 80%
}

.fontsize85 {
    font-size: 85%
}

.fontsize90 {
    font-size: 90%
}

.fontsize110 {
    font-size: 110%
}

.fontsize120 {
    font-size: 120%
}

.color1,
.color1 a {
    color: #56c1ff
}

.color2,
.color2 a {
    color: #2e4e4e
}

.background1 {
    background: #f4f4f4
}

.background2 {
    color: #fff;
    background: #252525
}

.background3 {
    background: #56c1ff
}

.margin0auto {
    display: block;
    margin: 0 auto
}

.lowercase {
    text-transform: lowercase
}

.uppercase {
    text-transform: uppercase
}

.mobile {
    display: none
}

.desktop {
    display: block
}

img {
    max-width: 100%
}

.aheight,
.centered-height {
    line-height: 1.2;
    display: inline-block;
    vertical-align: middle
}

.sep {
    display: block;
    width: 100%;
    height: 1px
}

a {
    transition: all .3s ease;
    color: #000
}

.h1,
.title,
h1 {
    font-size: 18px;
    font-weight: 700;
    color: #505050
}

.h2,
.stitle,
h2 {
    font-family: Open-sans,sans-serif;
    font-size: 30px;
    text-align: center;
    text-transform: uppercase;
    color: #555
}

.h3 .border_bot,
.stitle .border_bot,
h2 .border_bot {
    font-weight: 700;
    border-bottom: solid 3px #555
}

.h3,
.sstitle,
h3 {
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    color: #505050
}

.h4,
.ssstitle,
h4 {
    padding-top: 5px;
    text-transform: uppercase
}

.h5 {
    font-size: 11px;
    font-weight: 400;
    margin-top: -.5em;
    text-align: left;
    color: #999
}

section {
    margin-top: 30px!important
}

.button_blue {
    font-size: 12px;
    font-weight: 700;
    line-height: 1;
    display: inline-block;
    min-width: 0;
    min-height: 0;
    padding: 8px 18px;
    transition: all .3s ease-in-out;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    border-radius: 16px
}

.checkbox_style {
    line-height: 20px;
    position: relative
}

.checkbox_style input {
    position: absolute;
    visibility: hidden;
    opacity: 0
}

.checkbox_style label {
    padding-left: 20px
}

.checkbox_style label::after,
.checkbox_style label::before {
    position: absolute;
    top: 4px;
    left: 0;
    display: block;
    width: 14px;
    height: 14px;
    content: ''
}

.checkbox_style label::before {
    background-color: #fff
}

.checkbox_style input:checked + label::after {
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    padding-top: 0;
    padding-left: 1px;
    content: '\f00c';
    color: #737373;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.expand_box {
    line-height: 1.2;
    overflow: hidden;
    transition: all .5s ease-in-out
}

body.color_v1 #certif .highlight,
body.color_v1 .couleur1 {
    color: rgba(250,89,87,.7)
}

body.color_v1 .couleur1_bg {
    background-color: rgba(250,89,87,.7)
}

body.color_v1 #localisation .reseau li::before {
    background-color: rgba(250,89,87,.7)
}

body.color_v1 .couleur1_bg_news {
    background-color: rgba(250,89,87,.4)
}

body.color_v1 .color1_link {
    color: rgba(250,89,87,.7)
}

body.color_v1 .color1_link:hover {
    color: rgba(250,89,87,1)
}

body.color_v1 .button_color1 {
    color: #fff;
    background-color: rgba(250,89,87,.7)
}

body.color_v1 .button_color1:focus,
body.color_v1 .button_color1:hover {
    color: #fff;
    background-color: rgba(250,89,87,1)
}

body.color_v1 .button_color1_back {
    color: #fff;
    border: 1px solid rgba(250,89,87,.7);
    background-color: rgba(250,89,87,.7)
}

body.color_v1 .button_color1_back:hover {
    color: rgba(250,89,87,.7);
    background-color: #fff
}

body.color_v1 .couleur2 {
    color: #a1bfd8
}

body.color_v1 .couleur2_bg {
    background-color: #a1bfd8
}

body.color_v1 .couleur2_light {
    color: #b4c8d8
}

body.color_v1 .couleur2_bg_light {
    background-color: #b4c8d8
}

body.color_v1 .couleur2_bg_flash {
    background-color: #269dff
}

body.color_v1 #footer .infos_footer > div:not(:last-child) {
    border-bottom-color: #269dff
}

body.color_v1 .button_color2_submit {
    color: #fff;
    border: 1px solid #a1bfd8;
    background-color: #a1bfd8
}

body.color_v1 .button_color2_submit:hover {
    color: #a1bfd8;
    background-color: #fff
}

body.color_v1 .button_color3 {
    background-color: #4a4a5c
}

body.color_v2 #certif .highlight,
body.color_v2 .couleur1 {
    color: rgba(234,193,36,.7)
}

body.color_v2 .couleur1_bg {
    background-color: rgba(234,193,36,.7)
}

body.color_v2 #localisation .reseau li::before {
    background-color: rgba(234,193,36,.7)
}

body.color_v2 .couleur1_bg_news {
    background-color: rgba(234,193,36,.4)
}

body.color_v2 .color1_link {
    color: rgba(234,193,36,.7)
}

body.color_v2 .color1_link:hover {
    color: rgba(234,193,36,1)
}

body.color_v2 .button_color1 {
    color: #fff;
    background-color: rgba(234,193,36,.7)
}

body.color_v2 .button_color1:focus,
body.color_v2 .button_color1:hover {
    color: #fff;
    background-color: rgba(234,193,36,1)
}

body.color_v2 .button_color1_back {
    color: #fff;
    border: 1px solid rgba(234,193,36,.7);
    background-color: rgba(234,193,36,.7)
}

body.color_v2 .button_color1_back:hover {
    color: rgba(234,193,36,.7);
    background-color: #fff
}

body.color_v2 .couleur2 {
    color: #6dec78
}

body.color_v2 .couleur2_bg {
    background-color: #6dec78
}

body.color_v2 .couleur2_light {
    color: #c5ecc8
}

body.color_v2 .couleur2_bg_light {
    background-color: #c5ecc8
}

body.color_v2 .couleur2_bg_flash {
    background-color: #28ec37
}

body.color_v2 #footer .infos_footer > div:not(:last-child) {
    border-bottom-color: #28ec37
}

body.color_v2 .button_color2_submit {
    color: #fff;
    border: 1px solid #6dec78;
    background-color: #6dec78
}

body.color_v2 .button_color2_submit:hover {
    color: #6dec78;
    background-color: #fff
}

body.color_v2 .button_color3 {
    background-color: #62e2ff
}

body.color_v3 #certif .highlight,
body.color_v3 .couleur1 {
    color: rgba(53,195,200,.7)
}

body.color_v3 .couleur1_bg {
    background-color: rgba(53,195,200,.7)
}

body.color_v3 #localisation .reseau li::before {
    background-color: rgba(53,195,200,.7)
}

body.color_v3 .couleur1_bg_news {
    background-color: rgba(53,195,200,.4)
}

body.color_v3 .color1_link {
    color: rgba(53,195,200,.7)
}

body.color_v3 .color1_link:hover {
    color: rgba(53,195,200,1)
}

body.color_v3 .button_color1 {
    color: #fff;
    background-color: rgba(53,195,200,.7)
}

body.color_v3 .button_color1:focus,
body.color_v3 .button_color1:hover {
    color: #fff;
    background-color: rgba(53,195,200,.55)
}

body.color_v3 .button_color1_back {
    color: #fff;
    border: 1px solid rgba(53,195,200,.7);
    background-color: rgba(53,195,200,.7)
}

body.color_v3 .button_color1_back:hover {
    color: rgba(234,193,36,.7);
    background-color: #fff
}

body.color_v3 .couleur2 {
    color: #c03679
}

body.color_v3 .couleur2_bg {
    background-color: #c03679
}

body.color_v3 .couleur2 {
    color: #c695a2
}

body.color_v3 .couleur2_bg_light {
    background-color: #c695a2
}

body.color_v3 .couleur2_bg_flash {
    background-color: #ef6488
}

body.color_v3 #footer .infos_footer > div:not(:last-child) {
    border-bottom-color: #ef6488
}

body.color_v3 .button_color2_submit {
    color: #fff;
    border: 1px solid #c03679;
    background-color: #c03679
}

body.color_v3 .button_color2_submit:hover {
    color: #c03679;
    background-color: #fff
}

body.color_v3 .button_color3 {
    background-color: #d02752
}

.background_cover_c {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    overflow: hidden;
    height: 810px;
    background-position: center;
    background-size: cover
}

#box_top {
    position: relative;
    overflow: hidden
}

#box_top .bg_mobile {
    position: absolute;
    top: 0;
    left: 0;
    display: none;
    width: 100%;
    height: 145px;
    background-position: center;
    background-size: cover
}

#slider_mag ul#box_top_slider {
    margin: 0
}

#slider_mag #box_top_slider li {
    margin-left: 0
}

#slider_mag .bx-controls.bx-has-pager {
    display: none
}

#slider_mag .bx-wrapper {
    position: absolute;
    width: 100%;
    margin: 0
}

#slider_mag .bx-wrapper .bx-controls-direction a.bx-prev::before {
    display: block;
    width: 0;
    height: 0;
    content: '';
    border-width: 10px 10px 10px 0;
    border-style: solid;
    border-color: transparent #000 transparent transparent
}

#slider_mag .bx-wrapper .bx-controls-direction a.bx-next::before {
    display: block;
    width: 0;
    height: 0;
    content: '';
    border-width: 10px 0 10px 10px;
    border-style: solid;
    border-color: transparent transparent transparent #000
}

body:not(#home) #infos_mag,
body:not(#home) #slider_mag,
body:not(#home) .mousehover {
    display: none
}

body:not(#home) #box_top {
    height: auto;
    background-image: none!important
}

body:not(#home) .contain_headfoo {
    margin-top: 0
}

#header {
    height: 78px
}

#bandeau {
    position: relative;
    margin: 0 auto;
    background-color: rgba(255,255,255,.95)
}

.contain_headfoo {
    width: 1050px;
    margin: 0 auto;
    margin-top: 35px
}

#bandeau .left_side {
    float: left
}

#bandeau .right_side {
    float: right
}

#bandeau #logo_magasin {
    overflow: hidden;
    max-width: 110px;
    height: 78px
}

#bandeau #logo_magasin a {
    transition: none
}

#logo_magasin.logo_alt_c {
    line-height: 78px;
    margin-left: 10px
}

#bandeau #logo_magasin a .cover_bg {
    min-width: 110px;
    max-width: 170px;
    height: 78px
}

#logo .logo {
    width: 270px;
    height: 76px;
    margin-right: 13px
}

#logo_magasin .logo {
    max-height: 78px
}

#bandeau .right_side .assist_header_c {
    font-size: 10px;
    position: absolute;
    top: -20px;
    right: 0
}

#header .assist_header {
    display: inline-block
}

#header .assist_header .link_content:hover {
    color: #6c7589
}

#header .assist_header .text {
    margin-right: 5px;
    color: #000
}

#header .assist_header .text a {
    color: #000
}

#header .assist_header .text_size {
    font-size: 14px;
    display: inline-block;
    text-align: center;
    color: #fff;
    background-color: #000
}

#header .assist_header .text_size:hover {
    background-color: #9fa8bd
}

#header .assist_header .moins {
    line-height: 8px;
    width: 11px;
    height: 11px
}

#header .assist_header .plus {
    line-height: 12px;
    width: 13px;
    height: 13px
}

#header .btn_favo_minisite {
    float: right;
    width: auto;
    height: 15px;
    margin-top: 22px;
    margin-left: 30px
}

#header .btn_favo_minisite a {
    display: block;
    padding: 9px;
    color: #fff;
    border-radius: 50%
}

#header .btn_favo_minisite a .icon {
    line-height: 1
}

#header .btn_favo_minisite .text {
    margin-left: 2px;
    text-transform: uppercase
}

#header .btn_favo_minisite .icon-dynamic::before {
    font-family: kfont!important;
    font-size: 17px;
    content: '\EA1B'
}

#header .btn_favo_minisite.mag_favorite .icon-dynamic::before {
    font-family: kfont!important;
    font-size: 17px;
    content: '\EA1C'
}

#btn_back_c {
    display: flex;
    margin-top: 10px;
    align-items: center
}

#btn_back_c .back_btn:hover {
    margin-left: -10px
}

#btn_back_c .icon,
#btn_back_c .text {
    color: #777
}

#btn_back_c .text {
    font-size: 16px;
    margin-right: 10px;
    margin-left: 10px;
    text-transform: uppercase
}

#btn_back_c .btn_reseaux {
    display: flex;
    margin-left: auto;
    align-items: center
}

#btn_back_c .btn_reseaux .icon {
    font-size: 30px;
    margin: 0 5px
}

#btn_back_c .btn_reseaux .icon-facebook-rounded {
    color: #3b5998
}

#btn_back_c .btn_reseaux .icon-twitter-rounded {
    color: #1da1f2
}

#btn_back_c .btn_reseaux .icon-linkedin-rounded {
    color: #0077b5
}

#btn_back_c .btn_reseaux .icon:hover {
    opacity: .8
}

#bloc_instagram h2 {
    font-family: Open-sans,sans-serif;
    font-size: 30px;
    font-weight: 700;
    display: inline-block;
    padding-bottom: 6px;
    text-align: center;
    text-transform: uppercase;
    color: #555;
    border-bottom: solid 3px #555
}

body.color_v1 #bloc_instagram h2 .highlight {
    color: rgba(250,89,87,.7)
}

body.color_v2 #bloc_instagram h2 .highlight {
    color: rgba(234,193,36,.7)
}

body.color_v3 #bloc_instagram h2 .highlight {
    color: rgba(53,195,200,.7)
}

body.color_v1 #bloc_instagram h2 .highlight:hover,
body.color_v2 #bloc_instagram h2 .highlight:hover,
body.color_v3 #bloc_instagram h2 .highlight:hover {
    color: #555
}

#bloc_instagram {
    padding-top: 2rem;
    text-align: center;
    background-color: #fff
}

#bloc_instagram_content .img_c {
    position: relative;
    margin-right: -1px;
    padding-top: 16.6%
}

#bloc_instagram_content .abs_container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%
}

#bloc_instagram_content {
    text-align: center;
    color: #ccc;
    border-top: solid 5px #a1bfd8;
    border-bottom: solid 5px #a1bfd8;
    background: #e7e7e7
}

body.color_v2 #bloc_instagram_content {
    border-top-color: #62e2ff;
    border-bottom-color: #62e2ff
}

body.color_v3 #bloc_instagram_content {
    border-top-color: #d02752;
    border-bottom-color: #d02752
}

#bloc_instagram_content li {
    list-style-type: none
}

#bloc_instagram_content a > img {
    height: 100%;
    max-height: 322px;
    object-fit: cover
}

#header:not(.sticky) .sticky_elt {
    display: none
}

header.sticky #sticky_header {
    position: fixed;
    z-index: 5;
    top: 0;
    right: 0;
    left: 50%;
    width: 100%;
    max-width: 1050px;
    height: 60px;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%)
}

header.sticky #sticky_header #bandeau {
    height: 60px;
    margin-top: 0
}

header.sticky #bandeau #logo_magasin {
    height: 60px
}

header.sticky #bandeau img.logo {
    height: 60px
}

header.sticky #logo_magasin.logo_alt_c {
    line-height: 64px
}

header.sticky #bandeau .btn_favo_minisite {
    margin-top: 12px
}

header.sticky #bandeau #logo {
    display: none
}

header.sticky #bandeau .btn_favo_minisite {
    float: none;
    margin-top: -36px;
    margin-right: 18px;
    margin-left: 18px
}

header.sticky #bandeau .infos_mag_sticky {
    font-size: 14px;
    margin-bottom: 16px;
    color: #505050
}

header.sticky #bandeau .infos_mag_sticky a {
    color: #505050
}

header.sticky #bandeau .infos_mag_sticky a:hover {
    color: #000
}

header.sticky #bandeau .infos_mag_sticky .highlight {
    font-weight: 700
}

header.sticky #bandeau .right_side {
    margin-top: 17px
}

header.sticky #bandeau .contact_sticky {
    width: 50px;
    height: 30px;
    padding-top: 1px;
    text-align: center
}

header.sticky #bandeau .contact_sticky a {
    display: block;
    padding-top: 4px
}

header.sticky #bandeau .contact_sticky .icon {
    font-size: 20px;
    line-height: 1.1;
    color: #fff
}

header.sticky .schedule_sticky {
    line-height: 30px;
    width: 150px;
    height: 30px;
    margin-right: 15px;
    margin-left: 8px;
    background-color: #df5757
}

header.sticky .schedule_sticky .hours_schedule {
    display: none
}

header.sticky .schedule_sticky .icon {
    font-size: 16px;
    margin: 0 0 2px 7px;
    color: #fff
}

header.sticky .schedule_sticky .state {
    font-size: 12px;
    display: block;
    float: none;
    width: 100%;
    margin-bottom: 2px;
    text-align: center;
    text-transform: none;
    color: #fff
}

header.sticky .schedule_sticky .close_state {
    display: block;
    width: 100%;
    background-color: #df5757
}

header.sticky .schedule_sticky .open_state {
    display: none;
    text-align: center
}

header.sticky .schedule_sticky.open {
    line-height: 30px;
    background-color: #1ca06c
}

header.sticky .schedule_sticky.open .hours_schedule {
    font-size: 12px;
    display: block;
    text-align: center;
    color: #fff
}

header.sticky .schedule_sticky.open .open_state {
    display: block;
    background-color: #1ca06c
}

header.sticky .schedule_sticky.open .close_state {
    display: none
}

#infos_mag {
    width: 440px;
    margin-top: 20px;
    padding: 15px 20px;
    text-transform: uppercase;
    color: #505050;
    background-color: rgba(255,255,255,.9)
}

#infos_mag .coordonnes {
    margin-top: 9px;
    margin-bottom: 14px
}

#infos_mag .adresse {
    font-size: 14px
}

#infos_mag .tel {
    float: right
}

#infos_mag .icon,
header.sticky #bandeau .icons_mag .icon {
    line-height: 1;
    border-radius: 50%;
    background-color: #505050
}

#infos_mag .icon-phone {
    font-size: 16px;
    margin-right: 2px;
    padding: 7px 6px 4px 6px
}

#infos_mag .icon-phone::before {
    display: inline-block;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    color: #fff
}

#infos_mag .tel a {
    color: #505050
}

#infos_mag .tel a:hover {
    opacity: .8
}

#infos_mag .icons_mag {
    position: relative;
    float: right;
    margin-top: 10px
}

#infos_mag .icons_mag .icon,
header.sticky #bandeau .icons_mag .icon {
    font-size: 17px;
    margin-right: 5px;
    padding: 6px 6px 4px 6px;
    color: #fff
}

#infos_mag .cartemo {
    position: absolute;
    z-index: 1;
    right: 0;
    bottom: -55px;
    display: inline-block;
    width: 106px;
    height: 30px;
    margin-top: 4px;
    margin-left: 15px;
    background-size: cover
}

#infos_mag .horaires_c {
    position: relative;
    margin-top: 15px
}

#infos_mag .horaires_c .day {
    width: 70%
}

#infos_mag .horaires_c .day > li {
    position: relative;
    margin: .6em 0
}

#infos_mag .horaires_c .hour {
    letter-spacing: -1px;
    word-spacing: -2px
}

#infos_mag .horaires_c .day .am {
    position: absolute;
    top: 0;
    left: 90px;
    min-width: 59px;
    text-align: center;
    text-transform: none
}

#infos_mag .horaires_c .day .pm {
    position: absolute;
    top: 0;
    left: 160px;
    min-width: 59px;
    margin-left: 13px;
    text-align: center;
    text-transform: none
}

#infos_mag .info_rdv {
    margin-top: 15px;
    text-transform: none
}

#infos_mag .horaires_c .hour_center {
    position: absolute;
    left: calc(50% + 20px);
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%)
}

#infos_mag .schedule {
    line-height: 58px;
    position: absolute;
    right: 2px;
    bottom: 24px;
    box-sizing: content-box;
    width: 141px;
    height: auto;
    margin: 0 auto;
    border: solid 4px #df5757;
    border-bottom: none
}

#infos_mag .schedule .hours_schedule {
    display: none
}

#infos_mag .schedule .icon {
    font-size: 16px;
    margin-right: 10px;
    margin-left: 0;
    color: #505050;
    background-color: #fff
}

#infos_mag .schedule .state {
    font-size: 15px;
    line-height: 27px;
    display: block;
    float: none;
    width: 100%;
    text-align: center;
    text-transform: none;
    color: #fff
}

#infos_mag .schedule .close_state {
    display: block;
    width: 100%;
    padding: 2px 0;
    background-color: #df5757
}

#infos_mag .schedule .open_state {
    line-height: 26px;
    display: none;
    padding: 2px 0;
    text-align: center
}

#infos_mag .schedule.open {
    line-height: 26px;
    text-align: center;
    border: solid 4px #1ca06c;
    border-bottom: none
}

#infos_mag .schedule.open .hours_schedule {
    line-height: 27px;
    display: block;
    text-align: center
}

#infos_mag .schedule.open .open_state {
    display: block;
    background-color: #1ca06c
}

#infos_mag .schedule.open .close_state {
    display: none
}

#infos_mag .sep {
    margin-top: 18px;
    margin-bottom: 24px;
    background-color: #000
}

#infos_mag .spe .spe_list {
    line-height: 1.2em;
    width: 100%
}

#infos_mag .spe li {
    font-size: 12px;
    font-style: italic;
    display: inline;
    margin-left: 0;
    color: #338184
}

#infos_mag .spe li:not(:last-child)::after {
    content: ' -'
}

#infos_mag .spe .btn_contact {
    line-height: 60px;
    height: 60px;
    margin: 0 auto
}

#infos_mag .spe .btn_contact a {
    display: block;
    color: #fff
}

#infos_mag .spe .btn_contact a:hover {
    opacity: .8
}

#infos_mag .spe .btn_contact .icon {
    font-size: 25px;
    margin: 4px 17px 0 17px;
    background-color: transparent
}

#infos_mag .spe .btn_contact a .text {
    font-size: 20px;
    font-weight: 700
}

#infos_mag .spe .spe_list.club {
    display: flex;
    align-items: center
}

#infos_mag .spe .spe_list.club img {
    width: 130px;
    height: 120px
}

#infos_mag .spe .club li:not(:last-child)::after {
    content: ''
}

#box_top .mousehover a {
    position: absolute;
    bottom: -80px;
    left: 50%;
    padding: 50px;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    border-radius: 50%
}

#box_top .mousehover .icon-mouse {
    font-size: 60px;
    display: inline-block;
    transition: all .5s ease;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-animation: moose;
    animation: moose;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    color: #fff
}

#box_top .mousehover a:hover .icon-mouse {
    color: #c1cae0
}

#footer {
    font-family: Noway,sans-serif;
    position: relative;
    display: inline-block;
    width: 100%;
    margin-bottom: -2px;
    padding-top: 20px;
    padding-bottom: 25px;
    color: #fff
}

#cms #footer {
    margin-bottom: -4px
}

#footer .sep_foo {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: 1px
}

#footer .infos_footer > div {
    display: inline-block;
    float: left;
    width: calc(100% / 4)
}

#footer .infos_footer .title_foo {
    font-family: Noway,sans-serif;
    margin-bottom: 16px;
    text-transform: uppercase
}

#footer .newsletter .input_text_newsletter {
    margin-top: 8px
}

#footer .newsletter .input_text_newsletter input[type=text]::-webkit-input-placeholder {
    font-style: italic
}

#footer .newsletter .input_text_newsletter input[type=text]:-ms-input-placeholder {
    font-style: italic
}

#footer .newsletter .input_text_newsletter input[type=text]::-ms-input-placeholder {
    font-style: italic
}

#footer .newsletter .input_text_newsletter input[type=text]::placeholder {
    font-style: italic
}

#footer .newsletter .input_text_newsletter input[type=text] {
    width: 190px;
    padding-left: 10px;
    border-radius: 4px 0 0 4px
}

#footer .newsletter .input_text_newsletter button,
#footer .newsletter .input_text_newsletter input[type=text] {
    display: block;
    float: left;
    height: 40px;
    border: none
}

#footer .newsletter .input_text_newsletter button {
    font-size: 18px;
    line-height: .8;
    width: 40px;
    cursor: pointer;
    transition: all .3s ease-in-out;
    color: #fff
}

#footer .newsletter .input_text_newsletter button:hover {
    opacity: .8
}

#msg_newsletter > div {
    display: inline-block;
    margin-top: 15px;
    padding: 8px 15px;
    border-radius: 4px
}

#msg_newsletter .success {
    background-color: #1ca06c
}

#msg_newsletter .error {
    background-color: #df5757
}

#footer .contact_mag .btn_blc {
    font-family: Montserrat,sans-serif;
    font-size: 12px;
    width: 180px;
    text-transform: uppercase;
    border-radius: 4px;
    background-color: #fff
}

#footer .contact_mag .btn_blc a {
    display: block;
    padding: 6px 15px
}

#footer .contact_mag .btn_blc a:hover .icon,
#footer .contact_mag .btn_blc a:hover .text {
    color: #c4505c
}

#footer .contact_mag .btn_blc .icon,
#footer .contact_mag .btn_blc .text {
    color: #800727
}

#footer .contact_mag .btn_blc .text {
    margin-left: 10px
}

#footer .contact_mag .btn_blc .icon {
    font-size: 20px;
    line-height: .8
}

#footer .opticien_com .list_monopti a {
    line-height: 24px;
    color: #fff
}

#footer .opticien_com .list_monopti a:hover {
    color: #eaabb0
}

#footer .savoir_plus img.logo_light {
    float: left;
    width: 60px
}

#footer .infos_txt_footer {
    line-height: 14px;
    margin-top: 0;
    text-align: justify
}

.footer_bot {
    font-family: Open-sans,sans-serif;
    font-weight: 700;
    color: #fff
}

.footer_bot .footer_bot_c {
    margin-top: 0;
    padding: 15px 0
}

.footer_bot .contact_mag {
    font-size: 12px;
    line-height: 20px;
    float: left;
    height: 20px;
    margin-top: 10px
}

.footer_bot .infos_mag > a {
    text-transform: capitalize;
    color: #fff
}

.footer_bot .infos_mag > a:hover {
    opacity: .7
}

.footer_bot #partage {
    margin-left: 15px
}

.footer_bot .social_c {
    width: 250px
}

.footer_bot .circle_container {
    display: inline-block;
    margin-left: 3px
}

.footer_bot .circle_container span {
    display: inline-block
}

.footer_bot .circle_container a {
    line-height: 32px;
    display: block;
    width: 32px;
    height: 32px;
    text-align: center;
    vertical-align: middle;
    border-radius: 50%;
    background: #fff
}

.footer_bot .circle_container a:hover {
    opacity: .8
}

.footer_bot .circle_container a .icon {
    font-size: 22px;
    line-height: 1;
    vertical-align: middle
}

.footer_bot .circle_container .icon-twitter {
    color: #1da1f2
}

.footer_bot .circle_container .icon-facebook22 {
    color: #3b5998
}

.footer_bot .circle_container .icon-google-plus {
    color: #dd4b39
}

.footer_bot .signature {
    font-size: 10px;
    float: right
}

.footer_bot .signature.soc_padding {
    margin-top: 0
}

.footer_bot .signature a {
    color: #fff
}

.footer_bot .sep {
    position: absolute;
    bottom: 19px;
    left: 0;
    background-color: #ef6488
}

#footer .savoir_plus .expand_link {
    display: none
}

.seo_localisation {
    line-height: 32px;
    height: 32px;
    padding-left: 14px;
    border-bottom: 1px solid #e9e9e9;
    background-color: #f5f5f5
}

.seo_localisation .breadcrumb,
.seo_localisation .breadcrumb a {
    color: #000
}

.seo_localisation .breadcrumb a:focus,
.seo_localisation .breadcrumb a:hover {
    text-decoration: underline
}

.seo_localisation .breadcrumb {
    display: inline-block
}

.seo_localisation .expand_link {
    float: right;
    margin-right: 30px
}

.seo_localisation .expand_link:hover {
    color: #9fa8bd
}

.seo_localisation .expand_box {
    overflow: hidden;
    max-height: 0;
    transition: all .5s ease;
    background-color: #f5f5f5
}

.seo_localisation .expand_box.expand {
    max-height: 3000px
}

.seo_localisation .localisation {
    margin-top: 14px;
    padding-bottom: 16px
}

.seo_localisation .localisation .title {
    font-weight: 700
}

.seo_localisation .localisation li {
    line-height: 1.6;
    margin-left: 0
}

.seo_localisation .localisation li a {
    color: #000
}

.seo_localisation .localisation li a:hover {
    color: #959595
}

#services .stitle {
    margin-bottom: 35px
}

#services a:hover {
    opacity: .8
}

#services .line1,
#services .line2 {
    margin: 15px auto 22px auto
}

#services .line1 div:last-child,
#services .line2 div:last-child {
    text-align: right
}

#services .line1 div a,
#services .line2 div a {
    position: relative;
    display: block
}

#services .img_container {
    height: 0;
    padding-top: 39%
}

#services .line1 .col-sm-12 .img_container,
#services .line2 .col-sm-12 .img_container {
    padding-top: 19%
}

#services .service_rdv .text {
    font-size: 22px;
    line-height: 30px;
    position: absolute;
    right: 35px;
    bottom: 10px;
    text-align: right;
    letter-spacing: -.4px;
    text-transform: uppercase;
    color: #fff
}

#services .service_lentilles .text .highlight,
#services .service_rdv .text .highlight {
    font-weight: 700
}

#services .service_lentilles .text {
    font-size: 17px;
    font-weight: 300;
    line-height: 22px;
    position: absolute;
    top: 12px;
    left: 10px;
    text-align: left;
    letter-spacing: -.6px;
    text-transform: uppercase;
    color: #000
}

#services .tiers_optique .text {
    font-size: 17px;
    font-weight: 300;
    line-height: 22px;
    position: absolute;
    top: 15px;
    left: 15px;
    text-align: left;
    letter-spacing: -.2px;
    text-transform: uppercase;
    color: #fff
}

#services .tiers_optique .text .highlight {
    font-size: 22px
}

#services .tiers_optique .button_services {
    font-size: 15px;
    font-weight: 700;
    position: absolute;
    bottom: 28px;
    left: 15px;
    padding: 6px 14px;
    color: #5b230a;
    background-color: #fff
}

#services .modal_paiement .text {
    font-size: 22px;
    font-weight: 300;
    line-height: 22px;
    position: absolute;
    top: 10px;
    right: 15px;
    text-align: right;
    letter-spacing: -.2px;
    text-transform: uppercase;
    color: #fff
}

#services .modal_paiement .button_services {
    font-size: 15px;
    font-weight: 700;
    position: absolute;
    right: 15px;
    bottom: 28px;
    padding: 6px 14px;
    color: #667183;
    background-color: #fff
}

#notre_mag .stitle {
    margin-bottom: 25px
}

#notre_mag p {
    font-family: Montserrat,sans-serif;
    font-weight: 300;
    line-height: 24px
}

#notre_mag .expand_notice {
    display: none
}

#photo_mag_c {
    overflow: hidden;
    max-height: 200px
}

#photo_mag_c .img_container {
    height: 0;
    padding-top: 41%
}

#slider_mag_c {
    overflow: hidden;
    max-height: 150px
}

#slider_mag_c .bx-wrapper {
    margin-bottom: 0!important
}

#slider_mag_c .bx-wrapper .bx-pager {
    bottom: 10px
}

.bx-wrapper .bx-pager.bx-default-pager a {
    width: 15px;
    height: 15px;
    border: grey solid 1px;
    border-radius: 50%;
    background-color: #fff
}

.bx-wrapper .bx-pager.bx-default-pager a.active,
.bx-wrapper .bx-pager.bx-default-pager a:hover {
    background-color: grey
}

#slider_mag_c li {
    margin-left: 0
}

.bx-wrapper .bx-viewport {
    left: 0!important;
    border: none;
    box-shadow: none
}

#slider_mag_c .img_container {
    height: 0;
    padding-top: 27%
}

#video .vid_iframe {
    width: 100%;
    height: 595px;
    margin: 0 auto
}

#news .stitle {
    margin-bottom: 18px
}

#news #active_news div.contain_bg {
    height: 174px;
    border-bottom: 6px solid #c03779
}

.actu .second_block {
    font-family: Montserrat,sans-serif;
    margin-top: -2px;
    padding: 5px 10px;
    color: #555
}

.actu .sub_title {
    font-size: 12px;
    font-weight: 700;
    line-height: 12px;
    overflow: hidden;
    height: 24px;
    margin-top: 5px;
    margin-bottom: 1em;
    text-transform: uppercase
}

#push_promo .img_c .img {
    background-position-y: bottom!important
}

#push_promo .ssstitle {
    color: #f2a628
}

#news .abs_container .first_block img,
.actu .abs_container .first_block img {
    width: 100%
}

.actu .abs_container .second_block {
    text-align: justify;
    background-color: #f2f2f2
}

.actu .abs_container .hover_block > span {
    color: #fff
}

#news .abs_container .hover_block .icon,
.actu .abs_container .hover_block .icon {
    font-size: 20px;
    display: inline-block;
    padding: 20px 21px 19px 20px;
    transition: all .3s ease-in-out;
    -webkit-transform: rotate(45deg) scale(.6);
    transform: rotate(45deg) scale(.6);
    color: #fff
}

#news .abs_container .hover_block:hover .icon,
.actu .abs_container .hover_block:hover .icon {
    -webkit-transform: rotate(45deg) scale(1);
    transform: rotate(45deg) scale(1)
}

#news .abs_container .hover_block,
.actu .abs_container .hover_block {
    position: absolute;
    top: 0;
    right: 9px;
    bottom: 2px;
    left: 10px;
    transition: all .3s ease-in-out;
    text-align: center;
    opacity: 0
}

#news .abs_container:hover .hover_block,
.actu .abs_container:hover .hover_block {
    opacity: 1
}

#news .abs_container .hover_block > span,
.actu .abs_container .hover_block > span {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    color: #fff
}

#news .abs_container .hover_block:hover .icon,
.actu .abs_container .hover_block:hover .icon {
    -webkit-transform: rotate(45deg) scale(1);
    transform: rotate(45deg) scale(1)
}

#news > .button_c {
    margin-top: 15px;
    text-align: center
}

.actu .abs_container .second_block p {
    font-size: 12px;
    line-height: 14px;
    overflow: hidden;
    height: 72px;
    margin-top: 5px;
    margin-bottom: 15px
}

#news_opticien .actu .abs_container .second_block p {
    height: 112px
}

#localisation header .icon {
    font-size: 18px;
    margin-right: 5px
}

#localisation header .text {
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    color: #7e7e7e
}

#map {
    z-index: 0;
    height: 250px;
    margin-top: 5px
}

#map .street_button a {
    font-size: 11px;
    line-height: 20px;
    position: absolute;
    z-index: 1000;
    bottom: 0;
    left: 0;
    display: inline-block;
    height: 20px;
    padding: 0 5px;
    vertical-align: middle;
    opacity: .7;
    color: #000;
    background: #fff
}

#map .street_button .icon-itineraire {
    font-size: 20px;
    display: inline-block;
    padding-right: 3px;
    vertical-align: middle;
    color: #000
}

#map .street_button a:hover {
    opacity: 1
}

#map.leaflet-fade-anim .leaflet-map-pane .leaflet-popup {
    bottom: -20px!important;
    left: -76px!important
}

.leaflet-container a.leaflet-popup-close-button {
    z-index: 1
}

#localisation .reseau {
    height: 250px;
    margin-top: 27px;
    padding: 0 28px;
    background-color: #f2f2f2
}

#localisation .reseau h3 {
    font-size: 15px;
    padding-top: 30px;
    padding-left: 13px
}

#localisation .reseau ul:first-child {
    margin-top: 1em
}

#localisation .reseau li::before {
    display: inline-block;
    width: 12px;
    height: 12px;
    margin-right: 5px;
    content: ' ';
    border-radius: 50%;
    background-color: #35c3c8
}

#localisation .reseau a {
    font-size: 14px;
    font-weight: 700;
    line-height: 34px
}

#localisation header .text:hover {
    color: #515151
}

#localisation #popup_content {
    padding: 5px
}

#localisation #popup_content .name {
    font-weight: 700;
    margin-bottom: 5px
}

#localisation #popup_content .tel {
    margin-top: 5px
}

#miroir_virtuel .title {
    font-size: 18px;
    margin-bottom: 15px
}

#brands_slider {
    text-align: center
}

#brands_slider .stitle {
    margin-bottom: 20px
}

.slider_marques {
    max-height: 110px
}

.slider_marques li {
    display: inline-block;
    float: none!important;
    margin-left: 0;
    vertical-align: middle
}

.slider_marques li img {
    width: auto!important;
    max-height: 90px!important;
    margin: 0 auto;
    transition: all .3s ease-in-out;
    -webkit-transform: scale(.9);
    transform: scale(.9);
    opacity: .4!important
}

.slider_marques li:hover img {
    -webkit-transform: scale(.95);
    transform: scale(.95);
    opacity: 1!important
}

.bx-wrapper .bx-viewport {
    box-sizing: content-box;
    border: none;
    background: 0 0;
    box-shadow: none
}

.slider_marques .bx-wrapper .bx-prev {
    left: -50px
}

.slider_marques .bx-wrapper .bx-next {
    right: -50px
}

.slider_marques .bx-wrapper .bx-controls-direction a.bx-next::before,
.slider_marques .bx-wrapper .bx-controls-direction a.bx-prev::before {
    font-family: kfont!important;
    font-size: 30px;
    font-weight: 400;
    font-style: normal;
    font-variant: normal;
    line-height: 1;
    line-height: 40px;
    display: block;
    width: 40px;
    height: 40px;
    margin: 0 auto;
    text-align: center;
    text-transform: none;
    color: #d9dacc;
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.slider_marques .bx-wrapper .bx-controls-direction a.bx-prev::before {
    display: block;
    width: 0;
    height: 0;
    content: '';
    border-width: 10px 10px 10px 0;
    border-style: solid;
    border-color: transparent #000 transparent transparent
}

.slider_marques .bx-wrapper .bx-controls-direction a.bx-next::before {
    display: block;
    width: 0;
    height: 0;
    content: '';
    border-width: 10px 0 10px 10px;
    border-style: solid;
    border-color: transparent transparent transparent #000
}

#brands_slider .button_c {
    margin-top: 15px
}

#team {
    text-align: center
}

#team li {
    width: 175px;
    margin: 0 1em
}

#team li img {
    width: 175px
}

#team .employee_fiche {
    margin-top: 15px
}

#team .employee_fiche .employee_name {
    margin-top: 5px;
    margin-bottom: 5px;
    text-transform: uppercase;
    -webkit-hyphens: none;
    hyphens: none;
    -ms-hyphens: none
}

#team .employee_fiche .employee_name .highlight {
    color: #35c3c8
}

#team .employee_fiche .employee_status {
    font-size: 11px;
    font-weight: 300
}

#news_opticien .stitle {
    margin-bottom: 30px
}

#news_opticien .actu .first_block div.contain_bg {
    height: 145px;
    border-bottom: 6px solid #d41d4e
}

#news_opticien .button_c {
    margin-top: 60px;
    text-align: center
}

#magazine_mo .news_img {
    position: relative;
    height: 320px
}

#magazine_mo .magazine_contenu .book {
    position: absolute;
    right: 35px;
    bottom: 90px
}

#magazine_mo .magazine_contenu .blue_box {
    display: flex;
    padding: 14px 8px 15px 8px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    background-color: #35c3c8
}

#magazine_mo .magazine_contenu .icon-doc {
    font-size: 32px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    color: #fff
}

#magazine_mo .magazine_contenu .btn_abonnement {
    font-family: Montserrat,sans-serif;
    font-size: 28px;
    position: absolute;
    bottom: 20px;
    left: 50%;
    width: 200px;
    padding: 5px 0;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    border: 1px #fff solid;
    background-color: rgba(0,0,0,.2)
}

#magazine_mo .magazine_contenu .btn_abonnement:hover {
    color: #000;
    background-color: rgba(255,255,255,.5)
}

#news #push_promo .img_c .img {
    width: 300px
}

#certif .stitle {
    margin-bottom: 38px
}

#certif .list_certifs {
    margin: 0 auto;
    text-align: center
}

#certif .cert {
    width: calc(100% / 4);
    margin-bottom: 20px;
    text-align: center
}

#certif .cert img {
    max-height: 100px
}

#rassurances {
    width: 60%;
    margin: 30px auto;
    text-align: center
}

body:not(#home) .main-content {
    padding-bottom: 30px;
    background-color: #f2f2f2
}

#formulaire-reduct {
    max-width: 700px;
    margin: 0 auto
}

.formulaire .stitle .highlight {
    display: inline-block;
    margin-bottom: 3px
}

.formulaire h2 {
    font-weight: 400
}

.formulaire h2 .highlight {
    font-weight: 700
}

.formulaire .row {
    line-height: 45px
}

.formulaire .select {
    display: inline-block
}

.formulaire .textarea {
    display: block
}

.formulaire .label-title {
    font-size: 13px;
    display: inline-block;
    width: 175px;
    text-transform: uppercase;
    -webkit-hyphens: none;
    hyphens: none;
    -ms-hyphens: none
}

.formulaire .label-title.no-width {
    width: auto
}

.formulaire input[type=email],
.formulaire input[type=select],
.formulaire input[type=tel],
.formulaire input[type=text],
.formulaire select {
    line-height: 36px;
    width: 300px;
    height: 36px;
    margin-top: 5px;
    margin-right: 50px;
    padding-left: 10px;
    border: 1px solid #a6a6ae
}

.formulaire select {
    padding-left: 5px
}

.formulaire textarea {
    width: calc(100% - 50px);
    padding: 5px
}

.formulaire .links {
    margin-top: 20px;
    text-align: center
}

.formulaire .back_hist {
    margin-right: 20px;
    padding: 10px 15px
}

.formulaire input[type=submit] {
    margin: 20px auto 0 auto;
    padding: 10px 15px;
    cursor: pointer;
    transition: all .3s ease-in-out
}

.formulaire input[type=file] {
    display: block
}

.formulaire input[type=submit]:hover {
    background-color: #fff
}

#master_brand .h2,
#master_brand h2 {
    font-family: inherit;
    font-weight: 700;
    margin-top: 20px;
    padding-bottom: 0;
    border: none
}

#master_brand .h3,
#master_brand h3 {
    font-size: 20px;
    margin-bottom: 20px;
    margin-left: 5px;
    text-transform: none;
    color: #555
}

#master_brand .title_c {
    margin-bottom: 30px;
    border-bottom: 1px solid #555
}

#master_brand .h3 .highlight,
#master_brand h3 .highlight {
    font-weight: 300
}

#master_brand li {
    margin-bottom: 40px;
    text-align: center
}

#master_brand .list_marques a {
    display: inline-block
}

#master_brand .icon_logo {
    width: 29px
}

#master_brand .list_marques .img_brand_c {
    height: 100px
}

#master_brand .list_marques .logo_marque {
    display: block;
    max-width: 240px;
    max-height: 80px;
    margin: 0 auto;
    transition: all .3s ease-in-out;
    -webkit-transform: scale(.9);
    transform: scale(.9);
    opacity: .7
}

#master_brand .list_marques .logo_marque:hover {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1
}

#master_brand .list_marques .text {
    font-size: 15px;
    text-align: center;
    text-transform: uppercase;
    color: #bbb
}

#news.body_news .list_mini_news:not(.list_news) li,
#offers_news .list_mini_news:not(.list_news) li {
    position: relative;
    width: calc(50% - 12px);
    margin-left: 0
}

#news.body_news .list_mini_news.list_news,
#offers_news .list_mini_news.list_news {
    margin-top: 20px
}

#news.body_news .list_mini_news.list_news li .abs_container,
#offers_news .list_mini_news.list_news li .abs_container {
    margin-right: 0
}

#news.body_news .list_mini_news .abs_container:nth-child(odd),
#offers_news .list_mini_news .abs_container:nth-child(odd) {
    margin-right: 10px
}

#news.body_news .list_mini_news:not(.list_news) .abs_container .img_news,
#offers_news .list_mini_news:not(.list_news) .abs_container .img_news {
    position: relative;
    display: block;
    min-height: 102px;
    background-color: #ccc
}

#news.body_news .list_mini_news .abs_container .second_block,
#offers_news .list_mini_news .abs_container .second_block {
    line-height: 15px;
    overflow: hidden;
    height: 120px;
    margin: 0 auto;
    margin-bottom: 10px;
    padding-bottom: 10px;
    text-align: justify;
    background-color: #fff
}

#news.body_news .list_mini_news.list_news .abs_container .second_block,
#offers_news .list_mini_news.list_news .abs_container .second_block {
    position: relative;
    height: 0;
    margin-bottom: 20px;
    padding-top: 60%
}

#news.body_news .list_mini_news.list_news .abs_container .second_block .abs_container,
#offers_news .list_mini_news.list_news .abs_container .second_block .abs_container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0
}

#news.body_news .list_mini_news .abs_container .contenu,
#offers_news .list_mini_news .abs_container .contenu {
    width: 85%;
    margin: 0 auto
}

#news.body_news .list_mini_news.list_news .abs_container .conten,
#offers_news .list_mini_news.list_news .abs_container .contenu {
    width: 100%;
    margin: 0;
    padding: 10px 18px
}

#news.body_news .list_mini_news.list_news .abs_container .first_block,
#offers_news .list_mini_news.list_news .abs_container .first_block {
    position: relative;
    height: 0;
    padding-top: 39.7%;
    background-color: #ccc
}

#news.body_news .list_mini_news li.type_fournisseur .first_block,
#offers_news .list_mini_news li.type_fournisseur .first_block {
    border-bottom: 6px solid #248128
}

#news.body_news .list_mini_news li.type_opticien .first_block,
#offers_news .list_mini_news li.type_opticien .first_block {
    position: relative;
    border-bottom: 6px solid #c03779
}

#news.body_news .list_mini_news li.type_cdo .first_block,
#offers_news .list_mini_news li.type_cdo .first_block {
    border-bottom: 6px solid #d41d4e
}

#active_news .date,
#news.body_news .list_mini_news .abs_container .first_block .date,
#offers_news .list_mini_news .abs_container .first_block .date {
    font-family: Montserrat,sans-serif;
    font-size: 12px;
    font-weight: 400;
    position: absolute;
    bottom: -2px;
    left: 0;
    padding: 3px 4px 0 4px;
    color: #fff;
    background-color: #c03779
}

#active_news .offre .date,
#offers_news .list_mini_news .offre .abs_container .first_block .date {
    background-color: #f2a628
}

#news.body_news .list_mini_news.list_news .abs_container .first_block,
#offers_news .list_mini_news.list_news .abs_container .first_block {
    width: 100%
}

#news.body_news .list_mini_news.list_news li .abs_container,
#offers_news .list_mini_news.list_news li .abs_container {
    position: relative;
    box-shadow: 0 0 10px 0 #cfcfcf;
    -o-box-shadow: 0 0 10px 0 #cfcfcf
}

#news.body_news .list_mini_news.list_news .abs_container .hover_block,
#offers_news .list_mini_news.list_news .abs_container .hover_block {
    right: 0;
    bottom: 0
}

#news.body_news .list_mini_news .abs_container .sub_title,
#offers_news #news_content .list_mini_news .abs_container .sub_title,
#offers_news .list_mini_news .abs_container .sub_title {
    font-size: 14px;
    font-weight: 700;
    line-height: 1.1;
    overflow: hidden;
    max-height: 58px;
    margin-top: auto;
    padding-top: 10px;
    padding-bottom: 0;
    text-transform: uppercase;
    -webkit-hyphens: none;
    hyphens: none;
    -ms-hyphens: none
}

#news.body_news .list_mini_news .abs_container p,
#offers_news .list_mini_news .abs_container p {
    font-size: 12px;
    line-height: 15px;
    overflow: hidden;
    height: 95px;
    margin-bottom: 0
}

#news.body_news .list_mini_news .abs_container .hover_block,
#offers_news .list_mini_news .abs_container .hover_block {
    position: absolute;
    top: 0;
    right: 9px;
    bottom: 10px;
    left: 0;
    transition: all .3s ease-in-out;
    text-align: center;
    opacity: 0
}

#news.body_news .list_mini_news .abs_container:hover .hover_block,
#offers_news .list_mini_news .abs_container:hover .hover_block {
    opacity: 1;
    background-color: rgba(53,195,200,.4)
}

#news.body_news .list_mini_news .abs_container .hover_block > span,
#offers_news .list_mini_news .abs_container .hover_block > span {
    color: #fff
}

#news.body_news .list_mini_news .abs_container .hover_block .icon,
#offers_news .list_mini_news .abs_container .hover_block .icon {
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    display: inline-block;
    padding: 20px 20.5px;
    transition: all .3s ease-in-out;
    -webkit-transform: rotate(45deg) scale(.6) translate(-75%);
    transform: rotate(45deg) scale(.6) translate(-75%);
    color: #fff;
    background-color: #35c3c8
}

#news.body_news .list_mini_news .abs_container .hover_block:hover .icon,
#offers_news .list_mini_news .abs_container .hover_block:hover .icon {
    -webkit-transform: rotate(45deg) scale(1) translate(-50%);
    transform: rotate(45deg) scale(1) translate(-50%)
}

#news.body_news .list_mini_news .abs_container .hover_block .icon:hover,
#offers_news .list_mini_news .abs_container .hover_block .icon:hover {
    background-color: #00a4a9
}

#news.body_news .btn_news,
#offers_news .btn_news {
    text-align: center
}

#news.body_news .bg_content,
#offers_news .bg_content {
    padding: 10px 28px;
    background: #fff
}

.list_mini_news li.type_cdo .first_block .date {
    display: none
}

.filter_header {
    position: relative
}

.sort_by_type {
    position: absolute;
    top: 0;
    right: 0
}

.sort_by_tag {
    line-height: 25px
}

.sort_by_tag ul,
.sort_by_tag ul > li {
    display: inline-block;
    margin: auto
}

.text_label {
    font-size: 12px;
    font-weight: 700
}

.bar {
    height: 1px;
    margin: 10px 0;
    background: #b3b3b3
}

.type {
    font-size: 11px;
    padding: 3px 5px;
    white-space: nowrap;
    color: #fff
}

.type.all_filter {
    background-color: #35c3c8
}

#current_news.fournisseur .tag,
.type.fournisseur {
    background-color: #248128
}

#current_news.cdo .tag,
.type.cdo {
    background-color: #d41d4e
}

#current_news.opticien .tag,
.type.opticien {
    background-color: #c03779
}

.type.offer {
    background-color: #f2a628
}

.tag {
    font-size: 11px;
    margin-right: 5px;
    padding: 3px 8px;
    white-space: nowrap;
    color: #555;
    border-radius: 10px;
    background-color: #d9dce5
}

.tag:focus,
.tag:hover {
    background-color: rgba(217,220,229,.8)
}

#current_news_content .tag {
    color: #fff;
    border-radius: 0
}

#current_news_content .tag:focus,
#current_news_content .tag:hover {
    opacity: .8
}

.sort_by_type,
.sort_container {
    line-height: 32px;
    min-height: 32px
}

.sort_by_type {
    float: right
}

.sort_by_type a:focus,
.sort_by_type a:hover {
    opacity: .8;
    color: #fff
}

.filtre_news {
    display: inline-block
}

.sort_container .ui-selectmenu-button {
    line-height: 32px;
    position: relative;
    display: inline-block;
    height: 32px;
    margin-left: 10px;
    padding: 0 70px 0 15px;
    text-align: left;
    border: 1px solid #000;
    border-radius: 0;
    background-color: #fff
}

.sort_container .ui-selectmenu-button:active,
.sort_container .ui-selectmenu-button:focus,
.sort_container .ui-selectmenu-button:hover {
    color: #2b2b2b;
    background-color: #f6f6f6
}

.ui-button .ui-icon {
    line-height: 20px;
    position: absolute;
    top: 6px;
    right: 3px;
    width: 20px;
    height: 20px;
    text-align: center;
    text-indent: inherit;
    background: 0 0
}

.sort_container .ui-button .ui-icon:before {
    font-family: kfont!important;
    font-weight: 400;
    font-style: normal;
    font-variant: normal;
    line-height: 1;
    display: inline-block;
    width: 0;
    height: 0;
    content: '';
    transition: all .2s ease-in-out;
    text-transform: none;
    border-width: 7px 6.5px 0 6.5px;
    border-style: solid;
    border-color: #000 transparent transparent transparent;
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.sort_container .ui-button.ui-selectmenu-button-open .ui-icon:before {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg)
}

.ui-menu .ui-menu-item {
    background: #fff
}

.ui-selectmenu-text {
    font-style: italic;
    margin-right: 5px
}

.ui-selectmenu-button.ui-button {
    width: auto
}

#news.body_news #news_content .bloc_actu ul.list_news > li {
    visibility: hidden;
    transition-duration: .5s;
    transition-property: opacity;
    opacity: 0
}

#news.body_news #news_content .bloc_actu ul.list_news > li.visible {
    display: block;
    visibility: visible;
    opacity: 1
}

#news.body_news #news_content .bloc_actu ul.list_news > li:not(.visible) {
    width: 0;
    height: 0;
    padding: 0
}

#news.body_news #current_news_content .img_header .cover_bg,
#offers_news.body_offers_news #current_news_content .img_header .cover_bg {
    position: relative;
    height: 430px
}

#news.body_news #news_content .h2,
#news_content h2,
#offers_news #news_content .h2,
#offers_news #news_content h2 {
    padding-top: 30px;
    padding-bottom: 15px
}

#news.body_news #current_news_content h2,
#offers_news.body_offers_news #current_news_content h2 {
    margin-top: 30px
}

#news.body_news #current_news_content .date,
#offers_news.body_offers_news #current_news_content .date {
    font-family: Montserrat,sans-serif;
    font-size: 13px;
    font-weight: 700;
    line-height: 1.3;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 9px 14px;
    color: #fff
}

#news.body_news #current_news_content .list_mini_news .date,
#offers_news.body_offers_news #current_news_content .list_mini_news .date {
    font-weight: 400;
    line-height: 1;
    bottom: -2px;
    left: 0;
    padding: 3px 4px 0 4px
}

#news.body_news #current_news.cdo .border_bottom,
#news.body_news #current_news.cdo .cover_bg .text,
#news.body_news #current_news.cdo .date {
    background-color: #d41d4e
}

#news.body_news #current_news.fournisseur .border_bottom,
#news.body_news #current_news.fournisseur .cover_bg .text,
#news.body_news #current_news.fournisseur .date {
    background-color: #248128
}

#news.body_news #current_news.opticien .border_bottom,
#news.body_news #current_news.opticien .cover_bg .text,
#news.body_news #current_news.opticien .date,
#offers_news.body_offers_news #current_news.opticien .border_bottom,
#offers_news.body_offers_news #current_news.opticien .cover_bg .text,
#offers_news.body_offers_news #current_news.opticien .date {
    background-color: #c03779
}

#current_news.cdo .date {
    display: none
}

#news.body_news #current_news .border_bottom,
#offers_news.body_offers_news .border_bottom {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: 8px
}

#news.body_news #current_news .cover_bg .text,
#offers_news.body_offers_news #current_news .cover_bg .text {
    font-family: Montserrat,sans-serif;
    font-size: 20px;
    position: absolute;
    right: 0;
    bottom: 0;
    padding: 9px 14px;
    text-transform: uppercase;
    color: #fff
}

#news.body_news #current_news .cover_bg .text .highlight,
#offers_news.body_offers_news .cover_bg .text .highlight {
    font-weight: 700
}

#news.body_news #current_news .white_box,
#offers_news.body_offers_news #current_news .white_box {
    padding: 25px 16px;
    color: #555;
    background-color: #fff
}

#news.body_news #current_news h1,
#offers_news.body_offers_news #current_news h1 {
    font-size: 28px;
    font-weight: 400
}

#news.body_news #current_news.cdo h1 {
    color: #d41d4e
}

#news.body_news #current_news.fournisseur h1 {
    color: #248128
}

#news.body_news #current_news.opticien h1,
#offers_news.body_offers_news #current_news.opticien h1 {
    color: #c03779
}

#news.body_news #current_news .stitle,
#offers_news.body_offers_news #current_news .stitle {
    text-align: left;
    border-bottom: none
}

#news.body_news #current_news .text,
#offers_news.body_offers_news #current_news .text {
    line-height: 1.3;
    margin-top: 20px;
    text-align: justify
}

#offers_news .filtre_news[data-filter=cdo],
#offers_news .filtre_news[data-filter=fournisseur] {
    display: none
}

#offers_news .list_mini_news li.offre .first_block {
    border-bottom: 6px solid #f2a628
}

#offers_news .list_mini_news .abs_container .hover_block .icon-download {
    font-size: 40px;
    padding: 10px 10.5px
}

#insurance h2,
#paiement h2 {
    margin-bottom: 50px
}

#insurance .main-content ul,
#paiement .main-content ul {
    list-style-type: disc
}

#insurance li,
#paiement li {
    line-height: 25px
}

#insurance .main_content,
#paiement .main_content {
    padding: 25px
}

#legal h1 {
    font-size: 30px;
    margin-top: 20px;
    text-transform: uppercase;
    color: #555
}

#legal h2 {
    font-size: 25px;
    text-transform: none
}

@-webkit-keyframes moose {
    0% {
        -webkit-transform: translateY(-45px);
        transform: translateY(-45px)
    }

    50% {
        -webkit-transform: translateY(-32px);
        transform: translateY(-32px)
    }

    100% {
        -webkit-transform: translateY(-45px);
        transform: translateY(-45px)
    }
}

@keyframes moose {
    0% {
        -webkit-transform: translateY(-45px);
        transform: translateY(-45px)
    }

    50% {
        -webkit-transform: translateY(-32px);
        transform: translateY(-32px)
    }

    100% {
        -webkit-transform: translateY(-45px);
        transform: translateY(-45px)
    }
}

#cms p {
    line-height: 1.3em;
    margin: .6em auto
}

#grid_builder.overlay_bg,
.cover_bg {
    position: relative;
    background-position: center center;
    background-size: cover
}

.contain_bg {
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: contain
}

#grid_builder.overlay_bg:before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    content: '';
    background: rgba(255,255,255,.9)
}

#cms {
    line-height: 1.3
}

.img_preview img {
    max-width: 300px
}

#formulaire-reduct input[type=file] {
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0
}

.label-img {
    font-weight: 300;
    max-width: 300px;
    margin: 15px 0;
    padding: 10px;
    transition: all .3s ease;
    text-align: center
}

.label-img:hover {
    background: #395d7b
}

#marques .pager_c {
    position: relative;
    margin-top: 20px
}

.pager_c ul {
    margin: 0;
    text-align: center
}

.pager_c li {
    display: inline-block;
    margin-left: 4px
}

.pager_c li .link,
.pager_c li a {
    font-size: 16px;
    line-height: 22px;
    display: block;
    height: 22px;
    padding-right: 7px;
    padding-left: 7px;
    color: #000;
    background-color: #b7c0db
}

.pager_c li.navig .link,
.pager_c li.navig a {
    font-size: 13px;
    line-height: 24px
}

.pager_c li.current .link,
.pager_c li.current .link:focus,
.pager_c li.current .link:hover,
.pager_c li.current a,
.pager_c li.current a:focus,
.pager_c li.current a:hover {
    color: #fff;
    background-color: #d02752
}

.pager_c li:not(.truncate) .link:focus,
.pager_c li:not(.truncate) .link:hover,
.pager_c li:not(.truncate) a:focus,
.pager_c li:not(.truncate) a:hover {
    background-color: #9299ae
}

.pager_c li.truncate .link:focus,
.pager_c li.truncate .link:hover,
.pager_c li.truncate a:focus,
.pager_c li.truncate a:hover {
    cursor: cursor
}

.link {
    position: relative
}

.sub_nav_content {
    position: absolute;
    z-index: 2;
    top: 45px;
    right: 0;
    left: 0;
    overflow: hidden;
    max-height: 0;
    transition: all .1s ease-in-out
}

#marques .sub_nav_content {
    top: 33px
}

.sub_nav_content .sub_nav {
    padding: 5px;
    text-align: left;
    border: 1px solid #e3e3e3;
    background-color: #fff
}

.truncate.shownav .sub_nav_content {
    max-height: 500px
}

.truncate.shownav > .link::after,
.truncate.shownav > .link::before {
    position: absolute;
    z-index: 3;
    left: 50%;
    width: 0;
    height: 0;
    content: '';
    border-style: solid
}

.truncate.shownav > .link::before {
    bottom: -11px;
    margin-left: -6px;
    border-width: 0 6px 9px 6px;
    border-color: transparent transparent #ccc transparent
}

.truncate.shownav > .link::after {
    bottom: -12px;
    margin-left: -5px;
    border-width: 0 5px 8px 5px;
    border-color: transparent transparent #fff transparent
}

.sub_nav li {
    margin: 1px
}

.pager_c .sub_nav .link {
    font-size: 10px;
    line-height: 20px;
    width: 20px;
    height: auto;
    min-height: 20px;
    padding-right: 0;
    padding-left: 0;
    text-align: center
}

.mp-menu > .mp-level {
    top: 50px
}

#menu_launcher {
    top: 3px;
    bottom: auto;
    left: 8px;
    width: 55px;
    height: 35px
}

#menu_launcher .text {
    font-size: 13px;
    position: relative;
    top: 7px;
    left: 20px;
    color: #35c3c8
}

.picto-menu {
    top: 2px;
    left: 21px;
    height: 3px;
    background: #35c3c8
}

.picto-menu:after,
.picto-menu:before {
    left: -10px;
    height: 3px;
    background: #35c3c8 none repeat scroll 0 0
}

#menu_launcher:hover .picto-menu {
    left: 16px
}

#menu_launcher:hover .picto-menu:after,
#menu_launcher:hover .picto-menu:before {
    left: 0
}

.menuhidden .picto-menu:after,
.menuhidden .picto-menu:before {
    height: 3px
}

#logo_mh {
    margin: 0 auto
}

#mp-menu #mh_close {
    top: 6px;
    right: 0;
    padding-top: 0;
    padding-right: 0
}

#mp-menu #logo_mh .logo {
    max-height: 55px
}

.symbol-close,
.symbol-close:after {
    background: #3d3d3d
}

@media (min-width:992px) {
    #menu_launcher {
        display: block
    }
}

@media (max-width:992px) {
    #header.sticky #menu_launcher {
        left: auto
    }

    #msg_newsletter > div {
        margin-top: 10px
    }
}

@media (max-width :767px) {
    .formulaire input[type=email],
  .formulaire input[type=select],
  .formulaire input[type=tel],
  .formulaire input[type=text],
  .formulaire select {
        margin-right: 15px
    }

    .formulaire textarea {
        width: calc(100% - 15px);
        height: 150px
    }

    .sticky_elt_bis.aheight {
        display: none
    }
}

@media (min-width:599px) {
    #menu_launcher {
        display: none
    }
}

@media (max-width:599px) {
    #msg_newsletter > div {
        margin-top: 6px;
        margin-left: 20px
    }

    #services .line1 .col-sm-12 .img_container {
        padding-top: 39%
    }

    body#home #box_top .bg_mobile {
        display: block
    }

    #bandeau #logo_magasin {
        max-width: none
    }

    #bandeau #logo_magasin a .cover_bg {
        min-width: 80px;
        max-width: 110px;
        height: 40px
    }
}

@media (max-width:499px) {
    #menu_launcher {
        left: auto
    }
}

.hidden,
.masque,
figcaption {
    display: none
}

.container {
    position: relative;
    width: 1000px;
    margin-right: auto;
    margin-left: auto
}

@media only screen and (max-width :1080px) {
    .container {
        width: 951px
    }

    .contain_headfoo {
        width: 960px
    }

    .list_mini_news .abs_container .sub_title {
        font-size: 12px;
        padding-top: 8px
    }

    .list_mini_news .abs_container p {
        margin-top: 5px
    }
}

@media screen and (min-width:768px) and (max-width:991px) {
    #news.body_news .list_mini_news.list_news .abs_container .second_block,
  #offers_news .list_mini_news.list_news .abs_container .second_block {
        padding-top: 85%
    }

    #news.body_news .list_mini_news .abs_container p,
  #offers_news .list_mini_news .abs_container p {
        height: 130px
    }
}

@media only screen and (max-width :991px) {
    .container {
        width: 737px
    }

    .contain_headfoo {
        width: 727px;
        margin-top: 35px
    }

    .mobile {
        display: block
    }

    .desktop {
        display: none
    }

    header.sticky #bandeau .btn_favo_minisite {
        margin-right: 5px;
        margin-left: 5px
    }

    header.sticky #bandeau .infos_mag_sticky .adresse {
        display: none
    }

    #bandeau .right_side .icons_mag_sticky {
        display: none
    }

    #bandeau_opeco .img_opeco {
        min-height: 100px;
        background-image: url(../img/theme3/opeco_mobile.jpg)
    }

    #footer {
        padding-bottom: 0
    }

    #footer .infos_footer > div {
        width: calc(100% / 2);
        min-height: 150px
    }

    #news.body_news .list_mini_news.list_news .abs_container .conten,
  #offers_news .list_mini_news.list_news .abs_container .contenu {
        padding: 0 10px
    }

    #news.body_news #news_content .h2,
  #news.body_news #news_content h2,
  #offers_news #news_content .h2,
  #offers_news #news_content h2 {
        padding-bottom: 0
    }

    .list_mini_news .abs_container .second_block {
        height: 130px
    }

    .list_mini_news .abs_container .sub_title {
        padding-top: 5px
    }

    .list_mini_news .abs_container p {
        font-size: 11px;
        margin-top: 5px
    }

    .list_mini_news.list_news .abs_container .second_block {
        padding-top: 60%
    }

    #news.body_news .list_mini_news .abs_container .sub_title,
  #offers_news .list_mini_news .abs_container .sub_title {
        font-size: 12px
    }

    #video .vid_iframe {
        height: 440px
    }
}

@media only screen and (max-width :767px) {
    .contain_headfoo,
  .container {
        width: calc(100% - 40px)
    }

    #localisation .reseau {
        padding: 0 15px
    }

    #localisation .reseau li {
        margin-left: 0
    }

    #services .service_rdv .text {
        font-size: 20px;
        line-height: 24px;
        right: 10px
    }

    #services .modal_paiement .button_services,
  #services .tiers_optique .button_services {
        bottom: 10px
    }

    #services .tiers_optique .text {
        font-size: 15px;
        top: 10px
    }

    #services .tiers_optique .text .highlight {
        font-size: 18px
    }

    #miroir_virtuel {
        text-align: center
    }

    #miroir_virtuel .content {
        margin-top: 15px
    }

    #miroir_virtuel .container img {
        display: inline-block
    }

    .footer_bot .signature {
        float: none;
        margin-top: 0;
        margin-left: 10px
    }

    .footer_bot .signature.soc_padding {
        margin-top: 0
    }

    .formulaire input[type=email],
  .formulaire input[type=select],
  .formulaire input[type=tel],
  .formulaire input[type=text],
  .formulaire select {
        margin-right: 15px
    }

    .formulaire textarea {
        width: calc(100% - 15px);
        height: 150px
    }

    .list_mini_news.list_news .abs_container .second_block {
        padding-top: 50%
    }

    #news.body_news #current_news .cover_bg .text,
  #news.body_news #current_news_content .date,
  #offers_news.body_offers_news #current_news .cover_bg .text,
  #offers_news.body_offers_news #current_news_content .date {
        font-size: 15px;
        padding: 7px
    }

    #news.body_news #current_news_content h2,
  #offers_news.body_offers_news #current_news_content h2 {
        font-size: 20px
    }
}

@media only screen and (max-width :599px) {
    #box_top .contain_headfoo {
        width: 100%;
        margin-top: 0
    }

    #box_top {
        position: relative;
        overflow: visible;
        height: 145px;
        background-position: center;
        background-size: cover
    }

    #home #box_top {
        margin-bottom: 610px
    }

    #slider_mag {
        display: none
    }

    #header {
        height: 40px
    }

    #bandeau {
        height: 40px
    }

    #bandeau #logo_magasin {
        position: absolute;
        top: 0;
        left: 50%;
        height: auto;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%)
    }

    #bandeau #logo_magasin.logo_alt_c {
        line-height: 40px;
        width: calc(100% - 105px);
        text-align: center
    }

    #logo_magasin .logo {
        max-height: 40px
    }

    #bandeau .left_side {
        position: relative;
        float: none
    }

    #bandeau .right_side {
        display: none
    }

    header.sticky #sticky_header #bandeau {
        height: 40px;
        margin-top: 0
    }

    #sticky_header #bandeau .infos_mag_sticky {
        display: none
    }

    header.sticky #bandeau .btn_favo_minisite {
        float: right;
        margin-top: 4px;
        margin-right: 10px
    }

    #header .btn_favo_minisite {
        margin-top: 5px;
        margin-right: 10px
    }

    #header .btn_favo_minisite a {
        padding: 7px
    }

    #box_top .mousehover {
        display: none
    }

    #infos_mag .stitle:first-child {
        display: block
    }

    #infos_mag .spe .spe_list {
        height: 45px
    }

    #infos_mag .tel {
        position: absolute;
        top: 76px;
        left: 10px
    }

    #infos_mag .icons_mag {
        margin-top: 9px
    }

    #infos_mag .cartemo {
        bottom: -48px
    }

    #infos_mag .horaires_c {
        margin-top: 42px;
        padding-top: 20px;
        border-top: 1px #000 solid
    }

    #infos_mag .horaires_c .day .am {
        left: 105px
    }

    #box_top #infos_mag {
        position: absolute;
        bottom: -590px;
        left: 0;
        width: 100%;
        padding: 0 10px
    }

    #infos_mag .title {
        margin-bottom: 32px
    }

    #infos_mag .spe .title {
        margin-bottom: 20px
    }

    #infos_mag .coordonnes {
        display: block;
        margin-top: -22px;
        margin-bottom: 0
    }

    #infos_mag .schedule {
        position: absolute;
        bottom: 368px;
        left: 0;
        box-sizing: border-box;
        width: calc(100% + 20px);
        margin: 0 -10px;
        border: solid 5px #df5757;
        border-right-width: 10px;
        border-left-width: 10px
    }

    #infos_mag .schedule.open {
        border: solid 5px #1ca06c;
        border-right-width: 10px;
        border-left-width: 10px
    }

    #infos_mag .schedule .open_state {
        padding: 3px 0;
        text-align: left
    }

    #infos_mag .schedule .hours_schedule {
        line-height: 32px;
        float: right;
        width: 135px
    }

    #infos_mag .schedule.open .hours_schedule {
        line-height: 32px
    }

    #infos_mag .schedule .state {
        float: left;
        width: calc(100% - 139px)
    }

    #infos_mag .schedule:not(.open) .state {
        width: 100%
    }

    #infos_mag .schedule .icon {
        font-size: 20px
    }

    #infos_mag .schedule .hours_schedule span:nth-child(2) {
        font-size: 15px
    }

    .main-content .stitle {
        font-size: 25px
    }

    #services .stitle {
        margin-bottom: 20px
    }

    #services .line1,
  #services .line2 {
        margin: 0 auto
    }

    #services .line1 > div,
  #services .line1 > div:last-child,
  #services .line2 > div,
  #services .line2 > div:last-child {
        text-align: center
    }

    #services .service_rdv .text {
        font-size: 22px;
        line-height: 30px;
        right: 35px
    }

    #services .line1 .col-sm-12 .img_container,
  #services .line2 .col-sm-12 .img_container {
        padding-top: 39%
    }

    #services .modal_paiement .button_services,
  #services .tiers_optique .button_services {
        bottom: 28px
    }

    #services .tiers_optique .text {
        font-size: 17px;
        top: 15px
    }

    #services .tiers_optique .text .highlight {
        font-size: 22px
    }

    #bandeau_opeco {
        margin: 0 auto
    }

    #notre_mag .expand_box {
        max-height: 48px
    }

    #notre_mag p {
        line-height: 16px
    }

    #notre_mag .expand_notice {
        line-height: 16px;
        display: block;
        float: right;
        width: 16px;
        height: 16px;
        background-color: #35c3c8
    }

    #notre_mag .expand_notice:hover {
        background-color: #00a4a9
    }

    #notre_mag .expand_notice .plus {
        font-size: 20px;
        margin-left: 2px;
        color: #fff
    }

    #notre_mag .expand_notice .plus:nth-child(2) {
        line-height: 0;
        margin-left: 4px
    }

    #notre_mag .expand_box.expand {
        max-height: 1000px
    }

    #slider_mag_c {
        margin-top: 15px
    }

    #news .row > div:first-child {
        margin-bottom: 15px
    }

    #news .list_mini_news {
        margin: 0 -20px
    }

    #news .list_mini_news .abs_container .hover_block {
        right: -10px;
        left: -10px
    }

    #news.body_news #current_news_content .date,
  #offers_news.body_offers_news #current_news_content .date {
        top: 0;
        bottom: auto
    }

    #localisation #map {
        margin: 5px -20px 0 -20px
    }

    #localisation .reseau {
        height: auto;
        padding-bottom: 5px
    }

    #localisation .reseau a {
        line-height: 24px
    }

    #localisation .reseau h3 {
        padding-top: 20px
    }

    #brands_slider {
        margin-top: 20px
    }

    #team {
        margin-bottom: 25px
    }

    #video .vid_iframe {
        height: 330px
    }

    #news_opticien {
        margin-bottom: 30px
    }

    #news_opticien .stitle {
        margin-bottom: 20px
    }

    #news_opticien .button_c {
        margin-top: 10px
    }

    #news_opticien li {
        margin-bottom: 15px;
        text-align: center
    }

    #certif .cert {
        width: calc(100% / 2 - 15px)
    }

    #rassurances {
        margin-bottom: 20px
    }

    #rassurances .rassurance {
        margin-bottom: 10px
    }

    #footer {
        border-bottom: none
    }

    #footer .infos_footer > div {
        width: calc(100%);
        min-height: 0;
        margin-bottom: 20px
    }

    #footer .infos_footer > div:not(:last-child) {
        padding-bottom: 20px;
        border-bottom-width: 1px;
        border-bottom-style: solid
    }

    #footer .savoir_plus .expand_link {
        display: block;
        visibility: visible
    }

    #footer .expand_box p {
        line-height: 18px;
        overflow: hidden;
        max-height: 36px;
        transition: all .5s ease
    }

    #footer .expand_box.expand p {
        max-height: 1000px
    }

    #footer .expand_notice {
        line-height: 12px;
        display: block;
        float: right;
        width: 16px;
        height: 16px
    }

    #footer .expand_notice .plus {
        font-size: 20px;
        margin-left: 3px;
        color: #fff
    }

    #footer .expand_notice .plus:nth-child(2) {
        font-size: 26px;
        line-height: 12px;
        margin-left: 4px
    }

    .footer_bot .footer_bot_c {
        padding: 5px 0
    }

    .footer_bot .signature {
        font-size: 12px;
        margin-top: 5px
    }

    #footer .opticien_com ul {
        margin: 0
    }

    .list_mini_news.list_news .abs_container .second_block {
        padding-top: 55%
    }

    .list_mini_news.list_news .abs_container .second_block {
        padding-top: 55%
    }

    .sort_by_type {
        position: static;
        float: none;
        margin-top: 10px;
        text-align: left
    }
}

@media only screen and (max-width :499px) {
    .container {
        width: calc(100% - 20px)
    }

    .main-content .stitle {
        font-size: 16px
    }

    #infos_mag .horaires_c .day {
        width: 100%
    }

    #services img {
        width: 324px
    }

    #services .line1,
  #services .line2 {
        margin-right: -10px;
        margin-left: -11px
    }

    #services .col-xs-12 {
        padding-right: 0;
        padding-left: 0
    }

    #localisation header {
        margin-top: 10px
    }

    #rassurances {
        width: 100%
    }

    .footer_bot_c {
        font-size: 11px;
        width: calc(100% - 15px)
    }

    #footer {
        text-align: center
    }

    #footer .contain_headfoo {
        margin-top: 0
    }

    #footer .newsletter .input_text_newsletter input[type=text] {
        width: calc(100% - 40px)
    }

    #footer .newsletter .checkbox_style label {
        position: relative
    }

    #footer .newsletter .checkbox_style label .text {
        width: 205px;
        text-align: left
    }

    #footer .contact_mag .btn_blc {
        width: 100%
    }

    #footer .expand_notice .plus,
  #footer .expand_notice .plus:nth-child(2) {
        margin-left: 0
    }

    #news.body_news .list_mini_news.list_news .abs_container .second_block,
  #offers_news .list_mini_news.list_news .abs_container .second_block {
        padding-top: 60%
    }

    #news.body_news #news_content .h2,
  #news.body_news #news_content h2,
  #offers_news #news_content .h2,
  #offers_news #news_content h2 {
        font-size: 12px;
        max-height: 68px
    }

    #offers_news.body_offers_news #current_news_content h2 {
        font-size: 16px
    }

    #video .vid_iframe {
        height: 288px
    }

    .main-content .formulaire .stitle {
        font-size: 22px
    }

    .formulaire input[type=email],
  .formulaire input[type=select],
  .formulaire input[type=tel],
  .formulaire input[type=text],
  .formulaire select {
        width: calc(100% - 15px)
    }
}

@media screen and (min-width:400px) and (max-width:597px) {
    #news.body_news .list_mini_news.list_news .abs_container .second_block,
  #offers_news .list_mini_news.list_news .abs_container .second_block {
        padding-top: 38%
    }

    #news.body_news #news_content .h2,
  #news.body_news #news_content h2,
  #offers_news #news_content .h2,
  #offers_news #news_content h2 {
        font-size: 14px;
        max-height: 58px
    }
}